﻿const floatingLabels = require('../../ga/scripts/partials/floatinglabels');
const openModal = require('../../ga/scripts/partials/modals');

const $mainContent = $('#simon');
const $findNearbyContent = $('#FindNearbyMallsForm');
const $footerContent = document.querySelectorAll('footer');
const $headerContent = document.querySelectorAll('header');
const $searchContent = document.querySelectorAll('.search-links');
const $searchInput = document.querySelectorAll('.search-input-wrapper');
const $spoNav = document.querySelectorAll('.spo-nav');
const $modalTitle = $('emailSignupModalTitle');

function getCookie(cname) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while(c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if(c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function setCookie(name, value, exdays) {
  const exdate = new Date();
  exdate.setDate(exdate.getDate() + exdays);
  const newValue = encodeURI(value) + (exdays === null ? '' : '; expires=' + exdate.toUTCString());
  document.cookie = name + '=' + newValue + '; path=/;';
}

function closeSignupModal() {
  $mainContent.attr('aria-hidden', 'false');
  $findNearbyContent.attr('aria-hidden', 'false');
  $headerContent.forEach(element => element.setAttribute('aria-hidden', ''));
  $footerContent.forEach(element => element.setAttribute('aria-hidden', ''));
  $searchContent.forEach(element => element.setAttribute('aria-hidden', ''));
  $searchInput.forEach(element => element.setAttribute('aria-hidden', ''));
  $spoNav.forEach(element => element.setAttribute('aria-hidden', ''));
  document.getElementById('simon').querySelectorAll('a, button, input').forEach(element => element.setAttribute('tabindex', ''));
  document.getElementById('FindNearbyMallsForm').querySelectorAll('a, button, input').forEach(element => element.setAttribute('tabindex', ''));
  $headerContent.forEach(element => element.querySelectorAll('a, button, input').forEach(e => e.setAttribute('tabindex', '')));
  $footerContent.forEach(element => element.querySelectorAll('a, button, input').forEach(e => e.setAttribute('tabindex', '')));
  $searchContent.forEach(element => element.querySelectorAll('a, button, input').forEach(e => e.setAttribute('tabindex', '')));
  $searchInput.forEach(element => element.querySelectorAll('a, button, input').forEach(e => e.setAttribute('tabindex', '')));
  $spoNav.forEach(element => element.querySelectorAll('a, button, input').forEach(e => e.setAttribute('tabindex', '')));
  if(typeof ga !== 'undefined') {
    if(window.location.href.indexOf('premiumoutlets') >= 0) {
      ga.getAll()[0].send('event', {
        eventCategory: 'VIP Email Popup',
        eventAction: 'Close',
        eventLabel: window.location.href
      });
    } else {
      ga.getAll()[0].send('event', {
        eventCategory: 'Mall Insider Email Popup',
        eventAction: 'Close',
        eventLabel: window.location.href
      });
    }
  }
}
function emailSignupAlert(count) {
  openModal('emailSignupPrompt');
  document.querySelector('.modal-backdrop').addEventListener('click', closeSignupModal);
  $mainContent.attr('aria-hidden', 'true');
  $findNearbyContent.attr('aria-hidden', 'true');
  $headerContent.forEach(element => element.setAttribute('aria-hidden', 'true'));
  $footerContent.forEach(element => element.setAttribute('aria-hidden', 'true'));
  $searchContent.forEach(element => element.setAttribute('aria-hidden', 'true'));
  $searchInput.forEach(element => element.setAttribute('aria-hidden', 'true'));
  $spoNav.forEach(element => element.setAttribute('aria-hidden', 'true'));
  document.getElementById('simon').querySelectorAll('a, button, input').forEach(element => element.setAttribute('tabindex', '-1'));
  document.getElementById('FindNearbyMallsForm').querySelectorAll('a, button, input').forEach(element => element.setAttribute('tabindex', '-1'));
  $headerContent.forEach(element => element.querySelectorAll('a, button, input').forEach(e => e.setAttribute('tabindex', '-1')));
  $footerContent.forEach(element => element.querySelectorAll('a, button, input').forEach(e => e.setAttribute('tabindex', '-1')));
  $searchContent.forEach(element => element.querySelectorAll('a, button, input').forEach(e => e.setAttribute('tabindex', '-1')));
  $searchInput.forEach(element => element.querySelectorAll('a, button, input').forEach(e => e.setAttribute('tabindex', '-1')));
  $spoNav.forEach(element => element.querySelectorAll('a, button, input').forEach(e => e.setAttribute('tabindex', '-1')));
  $modalTitle.trigger('focus');

  if(typeof ga !== 'undefined') {
    if(window.location.href.indexOf('premiumoutlets') >= 0) {
      ga.getAll()[0].send('event', {
        eventCategory: 'VIP Email Popup',
        eventAction: 'Displayed',
        eventLabel: window.location.href
      });
    } else {
      ga.getAll()[0].send('event', {
        eventCategory: 'Mall Insider Email Popup',
        eventAction: 'Displayed',
        eventLabel: window.location.href
      });
    }
  }

  if(window.location.href.indexOf('premiumoutlets') >= 0) {
    setCookie('vipSignupPromptCount', count + 1, 60);
    sessionStorage.setItem('poSiteVisited', true);
  } else {
    setCookie('insiderSignupPromptCount', count + 1, 60);
    sessionStorage.setItem('mallSiteVisited', true);
  }
}

function emailSignupAlertCount() {
  // const mallSignupPromptCount = getCookie('emailSignupPromptCount');
  let signupPromptCount = 0;
  if(window.location.href.indexOf('premiumoutlets') >= 0) {
    signupPromptCount = getCookie('vipSignupPromptCount');
  } else {
    signupPromptCount = getCookie('insiderSignupPromptCount');
  }
  if(signupPromptCount !== '') {
    return parseInt(signupPromptCount, 10);
  }
  return 0;
}

(function (d) {
  // event listeners: one for selects and one for the switch
  d.addEventListener('DOMContentLoaded', function () {
    let sessionVisited = false;
    let mallSignupPromptCount = 0;
    if(location.href.indexOf('premiumoutlets') >= 0) {
      sessionVisited = sessionStorage.getItem('poSiteVisited');
    } else {
      sessionVisited = sessionStorage.getItem('mallSiteVisited');
    }
    mallSignupPromptCount = emailSignupAlertCount();
    const urlParams = new URLSearchParams(window.location.search);
    let member = urlParams.get('member');
    if(member === null) {
      member = 'N';
    }

    const vipLoggedIn = document.cookie.indexOf('VipAuth') !== -1;

    document.getElementById('signUpNowButton').addEventListener('click', function () {
      let mallID;
      let emailAddress;
      let separator = '?';
      if(document.getElementById('mallIDInput').value === '') {
        mallID = '';
      } else {
        mallID = 'mallid=' + document.getElementById('mallIDInput').value;
      }
      if(document.getElementById('emailAddress').value === '') {
        emailAddress = '';
      } else {
        emailAddress = '&email=' + encodeURIComponent(document.getElementById('emailAddress').value);
      }
      if(mallID === '' && emailAddress === '') {
        separator = '';
      }

      if(typeof ga !== 'undefined') {
        if(window.location.href.indexOf('premiumoutlets') >= 0) {
          ga.getAll()[0].send('event', {
            eventCategory: 'VIP Email Popup',
            eventAction: 'User Submission',
            eventLabel: window.location.href
          });
        } else {
          ga.getAll()[0].send('event', {
            eventCategory: 'Mall Insider Email Popup',
            eventAction: 'User Submission',
            eventLabel: window.location.href
          });
        }
      }

      if(location.href.indexOf('premiumoutlets') >= 0) {
        window.location.assign('/vip/register' + separator + mallID + emailAddress);
      } else {
        window.location.assign('/mall-insider' + separator + mallID + emailAddress);
      }
    });

    if(!sessionVisited && member.toUpperCase() !== 'Y' && !vipLoggedIn && !document.getElementById('wifiSuccessModal')) {
      if(mallSignupPromptCount < 3) {
        setTimeout(emailSignupAlert, 15000, mallSignupPromptCount);
      }
    }

    document.querySelector('#emailSignupPrompt button.close').addEventListener('click', closeSignupModal);
  }, false);
}(document));
