﻿module.exports = (function (modalId) {
  // define modal
  const $modal = $('#' + modalId);
  // close function - remove all open classes and backdrop
  const closeModal = function (e) {
    e.preventDefault();
    $('main').attr('aria-hidden', 'false');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
    $modal.removeClass('open in');
    return false;
  }
  // add class to body to set overflow hidden
  $('body').addClass('modal-open');
  // add modal backdrop
  $('body').append('<div class="modal-backdrop open"></div>');
  // open modal
  $modal.addClass('open');
  // find close icon and call close function
  $modal.find('.close, [data-dismiss="modal"]').click(closeModal);
  // close modal on backdrop click
  $('.modal-backdrop').click(closeModal);
  // handle Escape key click
  document.addEventListener("keydown",function(e){
    if(e.key === "Escape") {
        closeModal(e);
    }
  });
});
